<template>
  <v-sheet class="supplier" id="supplier">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <!-- <v-btn
          class="ml-2"
          v-if="stepperHeader.value > 1"
          :disabled="pageLoading"
          depressed
          tile
          v-on:click="stepperHeader.value--"
        >
          Previous
        </v-btn> -->
        <v-btn
          class="mr-2"
          v-if="stepperHeader.value > 1"
          :disabled="pageLoading"
          depressed
          tile
          v-on:click="stepperHeader.value--"
        >
          Previous
        </v-btn>
        <v-btn
          :disabled="pageLoading || !firstStepValid"
          :loading="pageLoading"
          class="white--text mx-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate"
        >
          <!-- () => {
                  if (stepper.value === 1) {
                    if (firstStepValid) {
                      stepper.value++;
                    }
                    // updateOrCreate();
                  } else if(stepper.value ===2) {
                    if (secondStepValid) {
                      stepper.value++;
                    }
                  }
                  else if(stepper.value ===3) {
                    updateOrCreate();
                  }
                } -->
          {{ stepperHeader.value === stepperHeader.header?.length ? "Save" : "Next" }}
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
    </v-row>

    <v-stepper v-model="stepperHeader.value" style="height: auto">
      <v-stepper-header>
        <v-stepper-step
          class="text-button"
          color="#0D47A1"
          v-for="(s_header, index) in stepperHeader.stepper_h"
          :key="`_stepper_contact_person${index + 1}_${s_header.title}`"
          :complete="stepperHeader.value > index + 1"
          :step="index + 1"
        >
          {{ s_header.title }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items class="my-auto py-0">
        <v-stepper-content step="1" class="my-auto py-0">
          <v-form
            ref="firstStep"
            v-model="firstStepValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate"
          >
            <v-row>
              <v-col md="8" class="my-auto py-0">
                <v-row>
                  <v-col md="12" class="">
                    <v-card outlined>
                      <v-card-title class="text-h5" style="font-weight: bold"
                        >SUPPLIER DETAILS</v-card-title
                      >
                      <v-divider class="my-auto py-0"></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col md="3" class="my-auto py-0">
                            <label for="primary-contact" class="btx-label mt-2 required"
                              >Primary Contact
                            </label>
                          </v-col>
                          <v-col md="9" class="py-0">
                            <v-layout>
                              <v-flex class="max-width-100px">
                                <SelectInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  :items.sync="listTitle"
                                  id="salutation"
                                  v-model="supplier.title"
                                  :rules="[vrules.required(supplier.title, 'Title')]"
                                  :class="{ required: !supplier.title }"
                                  placeholder="Title"
                                ></SelectInput>
                              </v-flex>
                              <v-flex class="mx-2">
                                <TextInput
                                  v-if="$route.params.id"
                                  hide-details
                                  disabled
                                  :loading="pageLoading"
                                  id="first-name"
                                  placeholder="First Name"
                                  v-model="supplier.first_name"
                                  :rules="[vrules.required(supplier.first_name, 'First Name')]"
                                  :class="{ required: !supplier.first_name }"
                                ></TextInput>
                                <TextInput
                                  v-else
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="first-name"
                                  placeholder="First Name"
                                  v-model="supplier.first_name"
                                  :rules="[vrules.required(supplier.first_name, 'First Name')]"
                                  :class="{ required: !supplier.first_name }"
                                ></TextInput>
                              </v-flex>
                              <v-flex>
                                <TextInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="last-name"
                                  v-model="supplier.last_name"
                                  placeholder="Last Name"
                                ></TextInput>
                              </v-flex>
                            </v-layout>
                          </v-col>

                          <v-col md="3" class="my-auto py-0">
                            <label for="company-name" class="btx-label mt-2 required"
                              >Company Name</label
                            >
                          </v-col>
                          <v-col md="9" class="py-0">
                            <TextInput
                              v-if="$route.params.id"
                              hide-details
                              disabled
                              :loading="pageLoading"
                              id="date-of-birth"
                              placeholder="Company Name"
                              v-model="supplier.company_name"
                              :rules="[vrules.required(supplier.company_name, 'Company Name')]"
                              :class="{ required: !supplier.company_name }"
                            ></TextInput>
                            <TextInput
                              v-else
                              hide-details
                              :loading="pageLoading"
                              id="date-of-birth"
                              placeholder="Company Name"
                              v-model="supplier.company_name"
                              :rules="[vrules.required(supplier.company_name, 'Company Name')]"
                              :class="{ required: !supplier.company_name }"
                            ></TextInput>
                          </v-col>
                          <v-col md="3" class="my-auto py-0">
                            <label for="display-name" class="btx-label mt-2 required"
                              >Display Name</label
                            >
                          </v-col>
                          <v-col md="9" class="py-0">
                            <TextInput
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="date-of-birth"
                              placeholder="Display Name"
                              v-model="supplier.display_name"
                              :rules="[vrules.required(supplier.display_name, 'Display Name')]"
                              :class="{ required: !supplier.display_name }"
                            ></TextInput>
                          </v-col>
                          <v-col md="3" class="">
                            <label for="business-description" class="btx-label mt-2"
                              >Business Description</label
                            >
                          </v-col>
                          <v-col md="9" class="py-0">
                            <TextAreaInput
                              v-model="supplier.description"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              placeholder="Description"
                              v-on:keypress="(e) => manageLimit(e)"
                              v-on:paste="(e) => onPaste(e)"
                              :counter="250"
                              :rows="5"
                              :cols="30"
                            >
                            </TextAreaInput>
                          </v-col>
                          <v-col md="3" class="my-auto py-0">
                            <label for="category" class="btx-label mt-2 required">Category</label>
                          </v-col>
                          <v-col md="9" class="py-0">
                            <v-flex class="d-flex align-center">
                              <SelectInput
                                hide-details
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                :items.sync="categorys"
                                id="category_list"
                                v-model="supplier.category"
                                placeholder="Select Category"
                                :rules="[vrules.required(supplier.category, 'Category')]"
                                :class="{ required: !supplier.category }"
                                class="me-2"
                              ></SelectInput>
                              <!-- <v-icon
                              class="cursor-pointer"
                              color="#0D47A1"
                              v-on:click="addContactPerson()"
                              >settings</v-icon
                            > -->
                            </v-flex>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- <v-col md="12" class="">
                  <v-card outlined>
                    <v-card-title class="text-h5" style="font-weight: bold"
                      >CONTACT DETAILS</v-card-title
                    >
                    <v-divider class="my-auto py-0"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col md="3" class="my-auto py-0">
                          <label for="company-email-address" class="btx-label mt-2 required"
                            >Email</label
                          >
                        </v-col>
                        <v-col md="9" class="py-0">
                          <EmailInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="company-email-address"
                            v-model="supplier.company_contact_email_address"
                            placeholder="Contact Email Address"
                            :rules="[
                              vrules.required(supplier.company_contact_email_address, 'Uen'),
                            ]"
                            :class="{ required: !supplier.company_contact_email_address }"
                          ></EmailInput>
                        </v-col>

                        <v-col md="3" class="my-auto py-0">
                          <label for="company-phone-no" class="btx-label mt-2 required"
                            >Phone no.</label
                          >
                        </v-col>
                        <v-col md="9" class="py-0">
                          
                          <PhoneTextField class="mt-3" required v-model="supplier.phone_number"   
                          placeholder="Phone"
  hide-details dense >
                    </PhoneTextField>
                         
                        </v-col>

                        <v-col md="3" class="py-0" style="vertical-align: baseline">
                          <label for="file" class="btx-label mt-4 right">Attachment</label>
                        </v-col>
                        <v-col md="9" class="mt-2 py-0" style="vertical-align: baseline">
                          <SupplierFile
                            id="file"
                            :directionColumn="false"
                            accept="image"
                            allowAddMore
                            v-model="supplier.code"
                          ></SupplierFile>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col> -->
                </v-row>
              </v-col>
              <v-col md="4" class="">
                <v-card outlined height="558">
                  <v-card-text>
                    <v-row>
                      <v-col
                        md="12"
                        align="center"
                        justify="center"
                        class="d-flex align-center justify-center"
                      >
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%">
                              <ImageTemplate :src="getProfileImage"></ImageTemplate>
                              <v-btn
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                class="white--text mx-2"
                                depressed
                                color="blue darken-4"
                                tile
                                style
                                v-on:click="fileInput()"
                              >
                                {{ supplier.image ? "Reset Image" : "Upload Image" }}
                                <v-icon right style="font-size: 21px">
                                  {{ `mdi-${supplier.image ? "refresh" : "cloud-upload-outline"}` }}
                                </v-icon>
                              </v-btn>
                              <div class="mt-3">
                                <label for="product-image" class="field-label mt-0 mb-1">
                                  Allowed JPG or PNG or JPEG.
                                </label>
                              </div>
                            </div>
                          </template>
                          <span> Upload Images </span>
                        </v-tooltip>
                      </v-col>

                      <v-col md="12" class="pa-0" v-show="false">
                        <v-row class="pa-0" style="margin: 0% !important">
                          <v-col md="2" sm="3" align="center" justify="center" class="pa-0 mt-2">
                            <v-file-input
                              id="product-image"
                              accept="image/png, image/jpeg, image/bmp"
                              ref="fileInput"
                              class="d-none"
                              v-on:change="updateProfileImage"
                            >
                            </v-file-input>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8">
                <v-container fluid>
                  <v-row>
                    <v-col md="12" class="my-auto py-3">
                      <h2 class="mt-2 text-h5" style="font-weight: 600">COMPANY CONTACT</h2>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="company-email-address" class="btx-label mt-2">Email</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <EmailInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="company-email-address"
                        v-model="supplier.company_email_address"
                        placeholder="Company Email Address"
                      ></EmailInput>
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                      <label for="company-phone-no" class="btx-label mt-2 required"
                        >Phone no.</label
                      >
                    </v-col>
                    <v-col md="9" class="py-0">
                      <!-- <PhoneInput
                        required
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="customer-phone-no"
                        placeholder="Phone No."
                        v-on:paste="(e) => onPastess(e)"

                        v-model="supplier.company_phone_number"
                        :rules="[
                              vrules.required(supplier.company_phone_number, 'Uen'),
                            ]"
                            :class="{ required: !supplier.company_phone_number }"
                      ></PhoneInput> -->
                      <PhoneTextField
                        class="mt-3"
                        v-model="supplier.company_phone_number"
                        placeholder="Phone"
                        hide-details
                        dense
                      >
                      </PhoneTextField>
                    </v-col>

                    <v-col md="3" class="py-0" style="vertical-align: baseline">
                      <label for="file" class="btx-label mt-4 right">Attachment</label>
                    </v-col>
                    <v-col md="9" class="py-2">
                      <SupplierFile
                        id="file"
                        :directionColumn="false"
                        :alignColumn="true"
                        accept="image"
                        allowAddMore
                        v-model="supplier.codes"
                      ></SupplierFile>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <SupplierContactPerson
            :key="
              defaut_person.first_name +
              defaut_person.title +
              defaut_person.display_name +
              defaut_person.last_name +
              defaut_person.company_email_address +
              defaut_person.company_phone_number
            "
            :persons="psersonsArr"
            :supplier="defaut_person"
            :primary_checkbox="primary_checkbox"
            v-on:saveContactPerson="updateContactPerson"
          ></SupplierContactPerson>

          <!-- <SupplierContactPersons.vue v-else  
            :persons="psersonsArr"
            :primary_checkbox="primary_checkbox"
            v-on:saveContactPerson="updateContactPerson"></SupplierContactPersons.vue> -->
        </v-stepper-content>

        <v-stepper-content step="3">
          <SupplierAddress
            :addresss="addressArr"
            v-on:saveAddress="updateAddressPerson"
          ></SupplierAddress>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- <Dialog :dialog="dialog">
      <template v-slot:title>
        <v-flex class="d-flex justify-content-between">
          <span>Category</span>
          <v-btn
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="addInput"
          >
            <v-icon>mdi-plus</v-icon>
            Add
          </v-btn>
        </v-flex>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-form
            ref="voucherForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-row>
              <v-col
                md="12"
                class="px-0 py-0"
                v-for="(input, index) in supplierCategory"
                :key="`catergoty_$_input_${index}`"
              >
                <v-row>
                  <v-col md="1" class="my-auto mb-1 py-0 px-0 text-right align-end">
                    <span style="font-weight: bold; color: black; font-size: 16px"
                      >{{ index + 1 }}.</span
                    >
                  </v-col>
                  <v-col md="10" class="py-0">
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="category_list"
                      v-model="supplier.category_input[index].label"
                      class="me-2"
                    ></TextInput>
                  </v-col>
                  <v-col md="1" class="my-auto mb-1 px-0 py-0 align-center">
                    <v-icon class="cursor-pointer" color="red" v-on:click="removeInput(index)"
                      >delete</v-icon
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate()"
        >
          Save
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile @click="dialog = false"> Cancel </v-btn>
      </template>
    </Dialog> -->

    <Dialog :dialog="dialog">
      <template v-slot:title>
        <v-layout>
          <v-flex>Category</v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text"
              depressed
              color="blue darken-4"
              tile
              v-on:click="addCategory()"
            >
              <v-icon left>mdi-plus</v-icon>
              Add...
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <template v-for="(row, ind) in categorys">
            <TextInput
              :key="ind"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`category-${ind}`"
              placeholder="category"
              v-model="row.name"
              append-outer-icon="mdi-delete"
              class="has-delete-outer"
              v-on:click:append-outer="removeCategory(ind)"
            ></TextInput>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateCategories()"
        >
          Save
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="cancel()"> Cancel </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneTextField from "@/view/components/PhoneTextField";
// import PhoneInput from "@/view/components/PhoneInput";
//import FileUpload from "@/view/components/FileUpload";
import SupplierFile from "@/view/components/SupplierFile";
import { QUERY } from "@/core/services/store/request.module";
import { UPDATE_SUPPLIERCATEGORY } from "@/core/lib/common.lib";

import ImageTemplate from "@/view/components/Image";
import SupplierContactPerson from "@/view/components/SupplierContactPerson";

import SupplierAddress from "@/view/components/SupplierAddress";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_SUPPLIER, UPDATE_SUPPLIER, GET_SUPPLIER } from "@/core/lib/supplier.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import FileManagerMixin from "@/core/mixins/file-manager/file.manager.mixin";
import { head, isEmpty } from "lodash";

export default {
  mixins: [FileManagerMixin],
  // name: "supplier-create",
  // title: "Create Supplier",
  data() {
    return {
      stepperHeader: {
        value: 1,
        stepper_h: [{ title: "Overview" }, { title: "Contact Persons" }, { title: "Address" }],
      },
      dialog: false,
      pageLoading: false,
      thirdStepValid: false,
      primary_checkbox: true,
      formValid: true,
      supplierId: null,
      defaut_person: {},
      codes: [],
      categorys: [],
      code: [],
      categoryList: [
        {
          text: "Computer & Electrionics",
          value: "eomputer & electrionics",
        },
        {
          text: "Manufacturing",
          value: "manufacturing",
        },
        {
          text: "Wholesale",
          value: "wholesale",
        },
        {
          text: "Distribution",
          value: "distribution",
        },
      ],
      psersonsArr: [],
      addressArr: {},
      secondStepValid: false,
      firstStepValid: false,
      supplier: {
        title: null,
        first_name: null,
        last_name: null,
        contactPerson: [],
        address: {},
        description: null,
        category: null,
        company_contact_email_address: null,
        display_name: null,
        phone_number: null,
        company_email_address: null,
        category_input: [{ label: "Category", value: "" }],
        image: null,
        profile_logo: null,
      },
    };
  },
  watch: {
    "supplier.title": {
      handler(param) {
        this.defaut_person["title"] = param;
      },
    },
    "supplier.first_name": {
      handler(param) {
        this.defaut_person["first_name"] = param;
        console.log({ first_name: param });
      },
    },
    "supplier.last_name": {
      handler(param) {
        this.defaut_person["last_name"] = param;
      },
    },
    "supplier.display_name": {
      handler(param) {
        this.defaut_person["display_name"] = param;
      },
    },
    "supplier.company_phone_number": {
      handler(param) {
        this.defaut_person["company_phone_number"] = param;
      },
    },
    "supplier.company_email_address": {
      handler(param) {
        this.defaut_person["company_email_address"] = param;
      },
    },
  },
  components: {
    Dialog,
    TextInput,
    TextAreaInput,
    EmailInput,
    SelectInput,
    PhoneTextField,
    SupplierFile,
    ImageTemplate,
    SupplierContactPerson,
    SupplierAddress,
    // PhoneInput,
  },
  methods: {
    // updatePrimaryContact(){
    //   this.$nextTick(()=>{
    //     this.defaut_person['title'] = this.supplier.title;
    //     this.defaut_person['first_name'] = this.supplier.first_name;
    //     this.defaut_person['last_name'] = this.supplier.last_name;
    //     this.defaut_person['display_name'] = this.supplier.display_name;
    //   })
    // },
    cancel() {
      this.dialog = false;
    },
    removeCategory(index) {
      this.categorys.splice(index, 1);
      if (this.categorys.length <= 0) {
        this.addCategory();
      }
    },
    async updateCategories() {
      const _this = this;
      try {
        _this.pageLoading = true;
        for (let i = 0; i < this.categorys.length; i++) {
          // if (this.categories && this.categories[i] && this.categories[i].file) {
          // 	formData.append(`file[${i}][file]`, this.categories[i].file);
          // }
          if (this.categorys && this.categorys[i] && !this.categorys[i].name) {
            _this.$store.commit(SET_ERROR, [{ model: true, message: "This field is required" }]);
            return false;
          }
        }
        await UPDATE_SUPPLIERCATEGORY({ options: _this.categorys });
        _this.dialog = false;
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Category Updated Successfully" },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    addCategory() {
      this.categorys.push({
        name: null,
      });
    },
    manageLimit(e) {
      if (this.supplier.description && this.supplier.description.length > 249) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.supplier.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 249) {
        let trimValue = finalval.substring(0, 250);
        this.supplier.description = trimValue;
        e.preventDefault();
      }
    },
    onPastes(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.supplier.phone_number;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 13) {
        let trimValue = finalval.substring(0, 14);
        this.supplier.phone_number = trimValue;
        e.preventDefault();
      }
    },
    onPastess(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.supplier.company_phone_number;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 13) {
        let trimValue = finalval.substring(0, 14);
        this.supplier.company_phone_number = trimValue;
        e.preventDefault();
      }
    },
    check() {},
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.categorys = data.supplierCategory;

          console.log(this.othercats);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    updateProfileImage(param) {
      console.log(param);
      const _this = this;
      _this.imageLoading = true;
      let fileExtention = param?.type.split("/");
      fileExtention = fileExtention[0];
      if (fileExtention != "image") {
        _this.$store.commit(SET_ERROR, [{ model: true, message: "Only Image required" }]);
        return false;
      }
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.supplier.profile_logo = head(response);
          _this.supplier.image = this.supplier.profile_logo.id;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },

    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
    removeInput(index) {
      this.supplier.category_input.splice(index, 1);
    },
    addInput() {
      this.supplier.category_input.push({
        label: "",
        value: "",
      });
    },
    pageTitle() {
      if (this.supplierId) {
        return "Update Supplier";
      }
      return "Create Supplier";
    },
    // async updateOrCreate() {
    //   const _this = this;

    //   if (!_this.$refs.supplierForm.validate()) {
    //     return false;
    //   }

    //   try {
    //     _this.pageLoading = true;
    //     if (_this.supplierId) {
    //       await UPDATE_SUPPLIER(_this.supplierId, _this.supplier).then((response) => {
    //         if (response.supplier && response.supplier.id) {
    //           _this.$router.replace({
    //             name: "supplier",
    //           });
    //         }
    //         _this.$store.commit(SET_MESSAGE, [
    //           { model: true, message: "Supplier Updated Successfully." },
    //         ]);
    //       });
    //     } else {
    //       const supplier = await CREATE_SUPPLIER(_this.supplier);

    //       _this.$router.replace({
    //         name: "supplier-detail",
    //         params: { id: supplier.id },
    //         query: { t: new Date().getTime() },
    //       });
    //       _this.$store.commit(SET_MESSAGE, [
    //         { model: true, message: "Supplier Created Successfully." },
    //       ]);
    //     }
    //   } catch (error) {
    //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     _this.pageLoading = false;
    //   }
    // },

    async updateOrCreate() {
      const _this = this;
      console.log(_this.supplier, "_this.supplierssss");

      // if (stepper.value === 1) {
      //               if (firstStepValid) {
      //                 stepper.value++;
      //               }
      //               // updateOrCreate();
      //             } else if(stepper.value ===2) {
      //               if (secondStepValid) {
      //                 stepper.value++;
      //               }
      //             }
      //             else if(stepper.value ===3) {
      //               updateOrCreate();
      //             }
      const _first_step = await _this.$refs["firstStep"].validate();
      if (_this.stepperHeader.value == 1 && _first_step) {
        _this.stepperHeader.value++;
      } else if (_this.stepperHeader.value == 2 && _this.supplier.contactPerson.length > 0) {
        _this.stepperHeader.value++;
      } else if (
        _this.stepperHeader.value == 3 &&
        this.supplier.address.address_line_1 &&
        this.supplier.address.postal_code &&
        this.supplier.address.country
      ) {
        try {
          _this.pageLoading = true;
          if (_this.supplierId) {
            await UPDATE_SUPPLIER(_this.supplierId, _this.supplier).then((response) => {
              if (response.supplier && response.supplier.id) {
                _this.$router.replace({
                  name: "supplier",
                });
              }
              _this.$store.commit(SET_MESSAGE, [
                { model: true, message: "Supplier Updated Successfully." },
              ]);
            });
          } else {
            console.log(2222);
            console.log(_this.supplier);
            const supplier = await CREATE_SUPPLIER(_this.supplier);

            _this.$router.replace({
              name: "supplier-detail",
              params: { id: supplier.id },
              query: { t: new Date().getTime() },
            });
            _this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Supplier Created Successfully." },
            ]);
          }
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      }

      // if (_this.stepper.value == 3 && !_this.$refs.secondStepValid.validate()) {
      //   return false;
      // }
    },
    updateContactPerson(param) {
      this.supplier.contactPerson = param;
    },
    updateAddressPerson(param) {
      this.supplier.address = param;
    },

    async getSupplier() {
      this.supplierId = this.$route.params.id;

      const supplier = await GET_SUPPLIER(this.supplierId);
      this.supplier = supplier;
      //this.supplier.profile_logo = {};
      // console.log(this.supplier.supplier)

      this.psersonsArr = this.supplier.persons;
      this.addressArr = this.supplier.supplier;
      // console.log(this.addressArr)
      // this.supplier =  this.supplier.supplier
      this.supplier = {
        title: supplier.supplier.title,
        first_name: supplier.supplier.first_name,
        last_name: supplier.supplier.last_name,
        description: supplier.supplier.description,

        display_name: supplier.supplier.display_name,
        company_name: supplier.supplier.company_name,
        category: supplier.supplier.category,
        phone_number: supplier.supplier.contact_number,
        company_phone_number: supplier.supplier.company_phone,
        company_contact_email_address: supplier.supplier.contact_email,

        company_email_address: supplier.supplier.company_email,
        profile_logo: {
          file: {
            url: supplier.supplier?.supplier_image?.url,
          },
          id: supplier.supplier?.t_id,
        },
        image: supplier.supplier?.t_id,
        //image:supplier.supplier?.supplier_image?.url,
      };

      console.log(this.supplier, "this.supplierss");
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: supplier.barcode, disabled: true },
      ]);
    },
    addContactPerson() {
      this.dialog = true;
    },
  },
  computed: {
    getProfileImage() {
      console.log(this.supplier, "supplierData");
      return isEmpty(this.supplier.profile_logo) === false
        ? this.supplier.profile_logo.file.url
        : process.env.VUE_APP_BASE_URL + "media/misc/no_photo_found.png";
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Supplier", disabled: true },
      { text: "Create", disabled: true },
    ]);
    // if (this.$refs.supplierForm.validate()) {
    //     return false;
    //   }
    const { name, params } = this.$route;
    if (name === "supplier-update") {
      const { id } = params;
      if (id) {
        this.customerId = toSafeInteger(id);
        this.getSupplier();
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light {
  height: 272px;
}
</style>
